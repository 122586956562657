import { gql } from '@apollo/client'

export const ACCOUNT = gql`
  query Account($id: ID!) {
    account(id: $id) {
      id
    }
  }
`

export const ACCOUNTS_BY_BUSINESS_TYPE = gql`
  query AccountsOfBusinessType($type: String!) {
    # This is an example of using aliases to run the same query with different arguments
    clients: accountsOfBusinessType(type: "Client") {
      id
      name
    }
    architects: accountsOfBusinessType(type: "Architect") {
      id
      name
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
