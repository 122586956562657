import { gql } from '@apollo/client'

export const LOCATION = gql`
  query Location($id: ID!) {
    location(id: $id) {
      id
    }
  }
`

export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
