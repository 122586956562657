import { gql } from '@apollo/client'

export const ACCOUNT_RELATIONSHIP = gql`
  query AccountRelationship($id: ID!) {
    accountRelationship(id: $id) {
      id
    }
  }
`

export const ACCOUNT_RELATIONSHIPS = gql`
  query AccountRelationships($pursuitId: ID!) {
    accountRelationships(pursuitId: $pursuitId) {
      id
    }
  }
`

export const CREATE_ACCOUNT_RELATIONSHIP = gql`
  mutation CreateAccountRelationship($input: CreateAccountRelationshipInput!) {
    createAccountRelationship(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const UPDATE_ACCOUNT_RELATIONSHIP = gql`
  mutation UpdateAccountRelationship($input: UpdateAccountRelationshipInput!) {
    updateAccountRelationship(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const DELETE_ACCOUNT_RELATIONSHIP = gql`
  mutation DeleteAccountRelationship($input: DeleteAccountRelationshipInput!) {
    deleteAccountRelationship(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
