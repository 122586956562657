import { gql } from '@apollo/client'

export const GET_MARKET = gql`
  query GetMarket($id: ID!) {
    market(id: $id) {
      id
      name
    }
  }
`

export const GET_ALL_MARKETS = gql`
  query GetAllMarkets {
    markets {
      id
      name
    }
  }
`
