import { gql } from '@apollo/client'

import { activityFragment } from './Activity.gql'
import { regionFragment } from './Region'
import { companyFragment } from './Company'
import { clientFragment } from './Client.gql'
import { preconstructionFragment } from './Preconstruction'
import { userFragments } from './User'
import { teamMemberFragments } from './TeamMemberInterface.gql'
import { constructionFragment } from './Construction'
import { errorMessageFragment } from './ErrorMessage.gql'

const projectFragments = {}

projectFragments.project = gql`
  fragment projectFragment on Project {
    id
    contract
    name
    phase
    address {
      street
      city
      state
      zip
      latitude
      longitude
    }
    company {
      ...companyFragment
    }
    region {
      ...regionFragment
    }
    clientNumber
    clientBusinessUnit
    clientRegion
    client {
      ...clientFragment
    }
    procedeoBilling {
      totalProjectValue
      totalProcedeoValue
      paidToDate
      remainingToBePaid
      costPercentComplete
      totalGCValue
      totalArchitectValue
      totalThirdPartyValue
      projectBillingLast30
      projectBillingLast90
      projectBillingBilledToDate
      projectBillingRemainingToBePaid
      procedeoBillingLast30
      procedeoBillingLast90
      procedeoBillingBilledToDate
      procedeoBillingRemainingToBePaid
    }
    procedeo {
      projectType
      projectNarrative
      constructionBudget
      contingency
      substantialCompletionDate
      openingDate
      newSF
      renovatedSF
    }
    preconstruction {
      summary
      phase
      totalSF
      constructionType
      constructionStart
      constructionFinish
      designStart
      designFinish
      projectDelivery
      constructionType
      clientManager {
        ...userFragment
      }
      preconManager {
        ...userFragment
      }
    }
    synced
    active
  }
  ${regionFragment}
  ${companyFragment}
  ${clientFragment}
  ${userFragments.user}
`

projectFragments.companyDashboard = gql`
  fragment projectCompanyDashboardFragment on Project {
    ...projectFragment
    clientNumber
    clientBusinessUnit
    clientRegion
    preconstruction {
      costRisk
      scheduleRisk
      currentEstimate {
        approvedProjectBudget
        currentConstructionEstimate
      }
      active
    }
    construction {
      active
      feeRisk
      cashFlowRisk
      gcgrRisk
      billingRisk
      cost {
        currentContract
      }
    }
  }
  ${projectFragments.project}
`

projectFragments.preconstructionDashboard = gql`
  fragment projectPreconstructionDashboardFragment on Project {
    ...projectFragment
    preconstruction {
      ...preconstructionFragment
    }
  }
  ${projectFragments.project}
  ${preconstructionFragment}
`
projectFragments.constructionDashboard = gql`
  fragment projectConstructionDashboardFragment on Project {
    id
    name
    contract
    region {
      id
      name
    }
    client {
      name
    }
    construction {
      active
      feeRisk
      cashFlowRisk
      gcgrRisk
      billingRisk
      cost {
        totalBudget
        totalVariance
        projectedFee
        currentContract
        costEstimatedPercent
        completionProjectedPercent
        substantialCompletionDate
      }
      teamMembers {
        id
        name
        title
      }
    }
  }
`

projectFragments.preconstructionDetail = gql`
  fragment projectPreconstructionDetailFragment on Project {
    ...projectFragment
    clientNumber
    clientBusinessUnit
    clientRegion
    users {
      ...userFragment
    }
    teamMembers {
      ...teamMemberFragment
    }
    photos {
      ...photoFragment
    }
    preconstruction {
      ...preconstructionFragment
    }
  }
  ${projectFragments.project}
  ${userFragments.user}
  ${teamMemberFragments.teamMember}
  ${preconstructionFragment}
`

projectFragments.constructionDetail = gql`
  fragment projectConstructionDetailFragment on Project {
    ...projectFragment
    activity {
      ...activityFragment
    }
    construction {
      ...constructionFragment
    }
  }
  ${projectFragments.project}
  ${activityFragment}
  ${constructionFragment}
`

projectFragments.settingsPage = gql`
  fragment settingsProjectFragment on Project {
    id
    contract
    name
    phase
    company {
      id
      domain
    }
    region {
      ...regionFragment
    }
    active
    synced
  }
  ${regionFragment}
`

projectFragments.projectTeamMembers = gql`
  fragment settingsProjectTeamMembersFragment on Project {
    id
    contract
    name
    phase
    teamMembers {
      id
      name
      title
      role
      email
      synced
      __typename
    }
    active
    synced
  }
  ${regionFragment}
`

projectFragments.result = gql`
  fragment projectResultFragment on ProjectResult {
    errors {
      ...errorMessageFragment
    }
    item {
      ...projectFragment
    }
  }
  ${projectFragments.project}
  ${errorMessageFragment}
`

export const LIST_PROJECTS_COMPANY_DASHBOARD = gql`
  query ProjectsByCompany($company: ID!) {
    projectsByCompany(company: $company) {
      ...projectCompanyDashboardFragment
    }
  }
  ${projectFragments.companyDashboard}
`

export const LIST_PROJECTS_PRECONSTRUCTION_DASHBOARD = gql`
  query ProjectsByCompany($company: ID!) {
    projectsByCompany(company: $company) {
      ...projectPreconstructionDashboardFragment
    }
  }
  ${projectFragments.preconstructionDashboard}
`

export const LIST_CLIENT_PROJECTS_CONSTRUCTION_DASHBOARD = gql`
  query ProjectsByCompany($company: ID!, $client: ID!) {
    projectsByCompanyAndClient(company: $company, client: $client) {
      ...projectConstructionDashboardFragment
    }
  }
  ${projectFragments.constructionDashboard}
`

export const LIST_COMPANY_PROJECTS_CONSTRUCTION_DASHBOARD = gql`
  query ProjectsByCompany($company: ID!) {
    projectsByCompany(company: $company) {
      ...projectConstructionDashboardFragment
    }
  }
  ${projectFragments.constructionDashboard}
`

export const LIST_PROJECTS_SETTINGS_PAGE = gql`
  query Projects($active: Boolean) {
    projects(active: $active) {
      ...settingsProjectFragment
    }
  }
  ${projectFragments.settingsPage}
`

export const GET_PROJECT_PRECONSTRUCTION_DETAIL = gql`
  query Project($id: ID!) {
    project(id: $id) {
      ...projectPreconstructionDetailFragment
    }
  }
  ${projectFragments.preconstructionDetail}
`

export const GET_PROJECT_CONSTRUCTION_DETAIL = gql`
  query Project($id: ID!) {
    project(id: $id) {
      ...projectConstructionDetailFragment
    }
  }
  ${projectFragments.constructionDetail}
`

export const ADD_PROJECT = gql`
  mutation AddProject($input: CreateProjectInput!) {
    addProject(input: $input) {
      ...projectResultFragment
    }
  }
  ${projectFragments.result}
`

export const ADD_PRECON_PROJECT = gql`
  mutation AddPreconProject($input: CreateProjectInput!) {
    addPreconProject(input: $input) {
      ...projectResultFragment
    }
  }
  ${projectFragments.result}
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $input: UpdateProjectInput!) {
    updateProject(id: $id, input: $input) {
      ...projectResultFragment
    }
  }
  ${projectFragments.result}
`

export { projectFragments }
