import { gql } from '@apollo/client'

const pursuitFragment = gql`
  fragment PursuitFragment on Pursuit {
    id
    name
    market {
      id
      name
    }
    totalVolume
    coreContractValue
    coreCompany {
      id
      name
    }
    deliveryMethod
    category
    region
    hideFromFutureOpportunities
    accountRelationships {
      account {
        id
        name
        businessTypes
        ownerType
        newClient
        customerVistaId
      }
      accountId
      id
      pursuitId
      relationshipType
    }
    locations {
      city
      id
      latitude
      longitude
      pursuitId
      state
      streetAddress
      zip
    }
    criticalDates {
      id
      criticalDate
      taskType
      description
      complete
      pursuitId
      status
    }
    coreFeeValue
    pursuitUsers {
      id
      pursuitId
      userId
      relationshipType
      user {
        name
      }
    }
    coreFeePercent
    awardDate
    year
    priority
    jcco
    status
  }
`

export const GET_PURSUIT = gql`
  query GetPursuit($id: ID!) {
    pursuit(id: $id) {
      ...PursuitFragment
    }
  }
  ${pursuitFragment}
`

export const GET_PURSUITS = gql`
  query GetPursuits {
    pursuits {
      ...PursuitFragment
    }
  }
  ${pursuitFragment}
`

export const CREATE_PURSUIT = gql`
  mutation CreatePursuit($input: CreatePursuitInput!) {
    createPursuit(input: $input) {
      item {
        ...PursuitFragment
      }
      errors {
        message
        path
      }
    }
  }
  ${pursuitFragment}
`

export const UPDATE_PURSUIT = gql`
  mutation UpdatePursuit($input: UpdatePursuitInput!) {
    updatePursuit(input: $input) {
      item {
        ...PursuitFragment
      }
      errors {
        message
        path
      }
    }
  }
  ${pursuitFragment}
`
