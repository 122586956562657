import { gql } from '@apollo/client'

export const GET_SUBCOMPANY = gql`
  query GetSubcompanyById($id: ID!) {
    subcompany(id: $id) {
      name
      id
    }
  }
`

export const GET_ALL_SUBCOMPANIES = gql`
  query GetSubcompanies {
    subcompanies {
      name
      id
    }
  }
`
