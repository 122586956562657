import { gql } from '@apollo/client'

export const CRITICAL_DATE = gql`
  query CriticalDate($id: ID!) {
    criticalDate(id: $id) {
      id
    }
  }
`

export const CREATE_CRITICAL_DATE = gql`
  mutation CreateCriticalDate($input: CreateCriticalDateInput!) {
    createCriticalDate(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const UPDATE_CRITICAL_DATE = gql`
  mutation UpdateCriticalDate($input: UpdateCriticalDateInput!) {
    updateCriticalDate(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`

export const DELETE_CRITICAL_DATE = gql`
  mutation DeleteCriticalDate($input: DeleteCriticalDateInput!) {
    deleteCriticalDate(input: $input) {
      item {
        id
      }
      errors {
        message
        path
      }
    }
  }
`
